<template>
  <div class="home">
    <img class="logoimg" alt="logo" src="../assets/logo.png">
    <h1 style="color: white;">字符水族馆 支持</h1>
    <h3 style="color: white; padding-bottom: 100px;" >Character Aquarium Support</h3>
    <div class="card-container">
      <Card  title="这是什么" description="一个神秘的水族馆"
      link="https://caquarium.dmxx.ltd/" linkText="我要使用"/>
    </div>
    <SearchBar/>
    <div class="footer">
      <a class="footerText">Copyright @ 2023 Damien</a>
    </div>
  </div>
</template>

<script>
import Card from '@/components/Card.vue'
import SearchBar from '@/components/SearchBar.vue'

export default {
  name: 'HomeView',
  components: {
    Card,
    SearchBar
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  background-color: black;
}
.logoimg {
  width: 200px;
  height: 200px;
  border: 2px solid white;
  border-radius: 50px;
  box-shadow: 0 0 10px 5px white ;
  margin: 50px;
}
.card-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  color: white;
}
.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.footerText{
  text-decoration: none;
  color: white;
  padding-top: 100px;
}
</style>